import React, { useState, useRef, useEffect } from 'react'
import Modal from 'react-modal';
import Bowser from "bowser";

import BrowserNotice from './BrowserNotice'

import style from './BrowserNotice.module.scss'

export default function BrowserNoticeModal() {
  const [ modalOpen, setModalOpen ] = useState(false)
  const currentBrowser = useRef()
  const browser = Bowser.getParser(window.navigator.userAgent);
  currentBrowser.current = browser.getBrowser()
  
  useEffect(() => {
    if(currentBrowser.current.name === 'Chrome' || currentBrowser.current.name === 'Firefox') {
      // allowed browsers
    } else {
      setModalOpen(true)
    }
  }, [currentBrowser])

  function closeModal() {
    setModalOpen(false)
  }

  return (
    <>
      <Modal 
        isOpen={modalOpen}
        shouldCloseOnOverlayClick={true}
        onRequestClose={closeModal}
        className="react-modal"
        overlayClassName="react-modal-overlay"
        contentLabel="Browser Notice"
      >
        <button className={style.close} onClick={closeModal}>close</button>
        <BrowserNotice />
      </Modal>
    </>
  )
}