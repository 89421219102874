import React, { useState } from 'react'
import Modal from 'react-modal';

import About from './About'

import style from './About.module.scss'

export default function AboutModal() {
  const [ modalOpen, setModalOpen ] = useState(false)

  function openModal() {
    setModalOpen(true)
  }

  function closeModal() {
    setModalOpen(false)
  }

  return (
    <>
      <button className={style.open} onClick={openModal}>
        about
      </button>
      <Modal
        isOpen={modalOpen}
        shouldCloseOnOverlayClick={true}
        onRequestClose={closeModal}
        className="react-modal"
        overlayClassName="react-modal-overlay"
        contentLabel="About"
      >
        <button className={style.close} onClick={closeModal}>
          close
        </button>
        <About />
      </Modal>
    </>
  );
}