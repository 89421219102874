import React from "react";

import style from './BrowserNotice.module.scss'

export default function BrowserNotice () {

  return (
  <div className={style.modal}>
    <p>Please use Chrome or Firefox to operate Malayeen.Space</p>
  </div>
  )
}