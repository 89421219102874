import React, { useContext, useEffect, useState, useRef } from "react";

import Store from "../../store/store";
import { ADD_TO_LIST } from "../../store/actionTypes";

import { recordService } from "../../services/recordService";

import Recorder from "../Recorder/Recorder";
import LocationInput from "../LocationInput/LocationInput";

import getDistance from "../../utilities/getDistance";

import style from "./Form.module.scss";

const INITIAL_STATE = {
  name: "",
  location: "",
  email: "",
  songname: "",
  origin: "",
  scene: "children",
  generation: "months",
  filename: "",
};

const sceneOptions = {
  children: "The Children",
  village: "The Village",
  revolution: "The Revolution",
};

const generationOptions = {
  months: "Months",
  generations: "Generations",
  dreams: "Dreams",
};

const debugType = localStorage.getItem("debug");
const debugRecords = debugType === "records";
const debugState = debugType === "state";
const MAX_STEPS = 7;

const Form = () => {
  const { dispatch, state } = useContext(Store);
  const [form, setForm] = useState(INITIAL_STATE);
  const [location, setLocation] = useState([1, 1]);
  const [origin, setOrigin] = useState([1, 1]);
  const [currentStep, setCurrentStep] = useState(0);
  const [loop, setLoop] = useState(true);

  const interval = useRef(null);

  if (debugRecords) {
    (async () => {
      const records = await recordService.fetchList();
      console.log(">> all records", records);
    })();
  }

  if (debugState) {
    console.log("state", state);
  }
  // pulse each step once
  useEffect(() => {
    if (state.inviteModal === "open") return;

    interval.current = setInterval(() => {
        setCurrentStep((step) => {
          if(loop && step === MAX_STEPS) {
            return 1;
          }

          return (step + 1);
        });
    }, 1500);

    return () => clearInterval(interval.current);
  }, [state.inviteModal]);

  useEffect(() => {
    if(!loop) {
      clearInterval(interval.current);
      setCurrentStep(0);
    }
    if (!loop && currentStep === MAX_STEPS) {
      clearInterval(interval.current);
    }
  }, [currentStep]);

  // update the db
  const formSubmit = (e) => {
    return new Promise(async (resolve, reject) => {
      let formData = {};

      try {
        if (form.name && form.email) {
          formData = await recordService.addToList(form);
        } else {
          formData = form;
        }

        // add dispatch for recorded data
        dispatch({
          type: ADD_TO_LIST,
          payload: formData,
        });

        resolve({
          formData: formData,
          distance: getDistance(location[0], location[1], origin[0], origin[1]),
        });
      } catch (error) {
        console.log("onSubmit error", error);
        reject(error);
      }
    });
  };

  const clearForm = () => {
    setForm(INITIAL_STATE);
  };

  const onFormChange = (e) => {
    const { name, value } = e.target;

    // update state
    setForm((prevState) => ({ ...prevState, [name]: value }));
  };

  const onInputFocus = (e) => {
    setLoop(false);
  };

  const onLocationChange = ({ name, value, lat, lng }) => {
    // update state
    setForm((prevState) => ({ ...prevState, [name]: value }));

    switch (name) {
      case "location":
        setLocation([lat, lng]);
        break;
      case "origin":
        setOrigin([lat, lng]);
        break;
      default:
      //
    }
  };

  return (
    <div className={style.form}>
      <label
        className={`${currentStep === 1 && style.current} ${style.location}`}
      >
        Where are you on this fine day?
        <br />
        [city, state or country]
        <LocationInput
          name="location"
          placeholder="your location"
          value={form.location}
          onFocus={onInputFocus}
          onChange={(event) => {
            if (event.target) {
              onFormChange(event);
            } else {
              onLocationChange(event);
            }
          }}
        />
      </label>
      <label
        className={`${currentStep === 2 && style.current} ${style.origin}`}
      >
        Where was your song born?
        <br />
        [city, state or country]
        <LocationInput
          name="origin"
          placeholder="song origin"
          value={form.origin}
          onChange={(event) => {
            if (event.target) {
              onFormChange(event);
            } else {
              onLocationChange(event);
            }
          }}
        />
      </label>
      <label className={`${currentStep === 3 && style.current} ${style.song}`}>
        Please name your song
        <br />
        <input
          type="text"
          name="songname"
          placeholder="song"
          value={form.songname}
          onChange={onFormChange}
        />
      </label>
      {/* <div
        className={`${currentStep === 4 && style.current} ${style.generation}`}
      > */}
      <label
        className={`${currentStep === 4 && style.current} ${style.generation}`}
      >
        It has been
        <select
          name="generation"
          id="generation"
          value={form.generation}
          onChange={onFormChange}
        >
          {Object.keys(generationOptions).map((generation, i) => (
            <option key={i} value={generation}>
              {generationOptions[generation]}
            </option>
          ))}
        </select>
        <br />
        since you stood under the stars in {form.origin}
      </label>
      {/* </div> */}
      {/* <div className={`${currentStep === 5 && style.current} ${style.scene}`}> */}
      <label className={`${currentStep === 5 && style.current} ${style.scene}`}>
        This song is for
        <select
          name="scene"
          id="scene"
          value={form.scene}
          onChange={onFormChange}
        >
          {Object.keys(sceneOptions).map((scene, i) => (
            <option key={i} value={scene}>
              {sceneOptions[scene]}
            </option>
          ))}
        </select>
      </label>
      {/* </div> */}
      <div
        className={`${currentStep === 6 && style.current} ${style.exchange}`}
      >
        <div className={style.info}>
          <input
            className={style.name}
            type="text"
            name="name"
            placeholder="your name"
            value={form.name}
            onChange={onFormChange}
          />
          <input
            className={style.email}
            type="text"
            name="email"
            placeholder="your email"
            value={form.email}
            onChange={onFormChange}
          />
          <p className={style.notice}>
            YOUR SONG CAN BE RECORDED AND SENT TO YOU. IT WILL NOT BE MADE
            PUBLIC WITHOUT YOUR SIGNED CONSENT. SUBMIT YOUR EMAIL BEFORE
            PRESSING "SING"
          </p>
        </div>
      </div>
      <Recorder
        onSubmit={formSubmit}
        clearForm={clearForm}
        step={currentStep}
      />
    </div>
  );
};

export default Form;
