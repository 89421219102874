import * as Tone from 'tone'

import AudioRecorder from '../recorder/AudioRecorder'
import effectsFactory from '../effects/effects'

// debug
const debugType = localStorage.getItem('debug')
const debug = debugType === 'player'

export default async function songPlayer(url, form, distance = 1) {
  const dest = Tone.context.createMediaStreamDestination()
  const recorder = new AudioRecorder(dest.stream)
  
  Tone.Destination.connect(dest)

  return new Promise(async (resolve, reject) => {
    try {
      const player = new Tone.Player(url, () => {
        if(debug) console.log('>> blob loaded for playback');
      })

      if (debug) console.log(`scene: ${form.scene}, generation: ${form.generation}, distance: ${distance}`)
      const effects = effectsFactory(form, distance)

      if (debug) console.log('effects', effects)
      // chain player to effect chain
      player.chain(...effects, Tone.Destination)
      // start playback after blob loaded
      player.autostart = true;

      player.onstop = () => {
        if (debug) console.log('>> playback finished. player + effects cleanup');
        recorder.stop()
        effects.forEach(e => e.dispose())
        player.dispose()
        resolve('stopped')
      }

      const audioBlob = await recorder.start(form, true) // fx = true
      if (debug) console.log('effects blob', audioBlob)
    } catch (error) {
      console.log('>> player error', error)
      reject(error)
    }
  })
}
