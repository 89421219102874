import React, { useState, useContext, useEffect } from "react";
import Modal from "react-modal";
import Store from "../../store/store";
import { INVITE_MODAL_STATE } from "../../store/actionTypes";

import Invite from "./Invite";

import style from "./Invite.module.scss";

export default function InviteModal() {
  const { dispatch } = useContext(Store);
  const [modalOpen, setModalOpen] = useState(true);

  useEffect(() => {
    dispatch({
      type: INVITE_MODAL_STATE,
      payload: "open",
    });
  }, []);

  function closeModal() {
    setModalOpen(false);
    dispatch({
      type: INVITE_MODAL_STATE,
      payload: "closed",
    });
  }

  return (
    <>
      <Modal
        isOpen={modalOpen}
        shouldCloseOnOverlayClick={true}
        onRequestClose={closeModal}
        className="react-modal"
        overlayClassName="react-modal-overlay"
        contentLabel="Invite"
      >
        <button className={style.close} onClick={closeModal}>
          close
        </button>
        <Invite />
      </Modal>
    </>
  );
}
