import React, { useRef, useContext } from 'react'
import { useFrame } from '@react-three/fiber'
import { Stars} from '@react-three/drei'

import Store from '../../store/store';

export default function StarField(props) {
  const { state: { mediaState } } = useContext(Store)
  const stars = useRef()
  
  useFrame(() => {
    if(stars.current) {
      switch(mediaState) {
        case 'stopped':
          break;
        case 'recording':
          stars.current.rotation.x += 0.00005
          stars.current.rotation.y -= 0.0005
          break;
        case 'playback':
          stars.current.rotation.x -= 0.00005
          stars.current.rotation.y += 0.0005
          break;
        default:
          
      }
    }
  })

  return (
    <Stars
      ref={stars}
      radius={100} // Radius of the inner sphere (default=100)
      depth={50} // Depth of area where stars should fit (default=50)
      count={5000} // Amount of stars (default=5000)
      factor={6} // Size factor (default=4)
      saturation={1} // Saturation 0-1 (default=0)
      fade // Faded dots (default=false)
    />    
  )
}
