export const RECORDER_INIT = "RECORDER_INIT";
export const RECORDER_START = "RECORDER_START";
export const RECORDER_STOP = "RECORDER_STOP";

export const SET_SCENE = "SET_SCENE";

export const FILE_UPLOAD = "FILE_UPLOAD";
export const FILE_PLAYBACK = "FILE_PLAYBACK";
export const MEDIA_STATE = "MEDIA_STATE";

export const LOAD_EFFECTS = "LOAD_EFFECTS";
export const CONNECT_EFFECTS = "CONNECT_EFFECTS";

export const INVITE_MODAL_STATE = "INVITE_MODAL_STATE";

// old
export const GET_LIST = "GET_LIST";
export const ADD_TO_LIST = "ADD_TO_LIST";
export const REMOVE_FROM_LIST = "REMOVE_FROM_LIST";
export const UPDATE_LIST = "UPDATE_LIST";

export const SET_ERROR = "SET_ERROR";

export const START_LOADING = "START_LOADING";
export const END_LOADING = "END_LOADING";

// locations
export const SET_LOCATION = "SET_LOCATION"
export const SET_ORIGIN = "SET_ORIGIN"
export const SET_DISTANCE = "SET_DISTANCE"

/*
UI
  RECORDER_INIT
  RECORD_START
  RECORD_STOP
  SET_SCENE

file
  FILE_UPLOAD
  FILE_PLAYBACK

effects
  LOAD_EFFECTS
  CONNECT_EFFECTS
*/