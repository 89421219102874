import * as Tone from 'tone'

import scenes from './scenes'
// import exponentialCurve from '../utilities/exponentialCurve'
import logarithmicCurve from '../utilities/logarithmicCurve'

const debugType = localStorage.getItem('debug')
const debug = debugType === 'effects'

// realworld max-distance between two points is ~19000km
//// but that doesn't sound as good since it's an outlier 
const MAX_DISTANCE = 13000 // km

// export array of effects for each scene
export default function effectsFactory(form, distance=1) {
  const {
    scene,
    generation
  } = form

  try {
    return scenes[scene].map(fx => {
      const {
        name: effectName,
        settings,
        modulator,
        modulatorParam,
        modulatorValues
      } = fx
      
      // build Tone effect
      const effect = new Tone[effectName]()

      // set default settings
      effect.set(settings);

      // update effect from form
      switch (modulator) {
        case 'generation':
          effect.set({
            [modulatorParam] : modulatorValues[generation]
          })

          break;
        case 'distance':
          // const paramValue = exponentialCurve(parseFloat(distance/MAX_DISTANCE)) * modulatorValues['max']
          const paramValue = logarithmicCurve(distance, MAX_DISTANCE) * modulatorValues['max']
          effect.set({
            [modulatorParam] : paramValue
          })

          break;      
        default:
          break;
      }

      if(debug) console.log('>> effect:', effectName, 'modulator:', modulator, 'modulatorParam:', modulatorParam)
      return effect
    })
  } catch (error) {
    console.error(`>> Could not setup effect: ${error.toString()}`)
  }
}

// https://www.npmjs.com/package/audio-oscilloscope

// https://www.npmjs.com/package/@teropa/oscilloscope