import { MediaRecorder, register } from 'extendable-media-recorder';
import { connect } from 'extendable-media-recorder-wav-encoder';
import axios from  'axios'

// debug
const debugType = localStorage.getItem('debug')
const debug = debugType === 'recorder'

export default class AudioRecorder {
  constructor(source) {
    this.options = { mimeType: 'audio/wav' }
    this.mimeType = { 'type': 'audio/wav' }
    this._chunks = []
    this.stream = source
    this._development = process.env.NODE_ENV === 'development'
    this.serverURL = this._development ? 'http://localhost' : 'https://malayeen.space'
    this.formData = {}
    this.fxRecording = false
    this.filename = false
  }

  _processChunks = () => {
    // build blob from chunks
    const blob = new Blob(this._chunks, this.mimeType);
    // build blob url to pass to Tone
    const url = URL.createObjectURL(blob);

    if (debug) console.log(`>> process chunks: ${blob.size}`)

    // optional source file download
    const urlParams = new URLSearchParams(window.location.search)

    if (urlParams.has('download') && JSON.parse(urlParams.get('download'))) {
      const anchor = document.createElement("a");
      anchor.download = this.filename;
      anchor.href = url;
      anchor.click();
    }

    // upload to server
    if(this.formData.name && this.formData.email) {
      this.uploadBlob(blob)
    }

    // reset chunks
    this._chunks = [];
    // reset media recorder
    this.mediaRecorder = null

    return {
      url: url,
      blob: blob
    }
  }

  init = async () => {
    if (debug) console.log('>> init media recorder')
    await register(await connect());
  }
  // TODO: move this out
  uploadBlob = async (blob) => {
    const { filename } = this.formData

    let file = new File([blob], this.fxRecording ? 'fx-' + filename : filename, {type: 'audio/wav'});
    
    const data = {
      "file" : "wav",
    };

    const fileFormData = new FormData();
    fileFormData.append('audio', file);
    fileFormData.append('data', JSON.stringify(data));
    try {
      const response = await axios.post(`${this.serverURL}/upload`, fileFormData);
      return response.data.data;
    } catch (error) {
      console.log('>> error uploading file', error)
      // return error;
    }
  }

  start = (form, fx=false) => {
    try {
      this.mediaRecorder = new MediaRecorder(this.stream, this.options);

      // set data
      this.fxRecording = fx
      this.formData = form
    } catch (error) {
      console.error(`>> Couldn't get media stream: ${error.toString()}`);
    }

    // collect audio chunks
    // this.mediaRecorder.ondataavailable = (e) => {
    //   this._chunks.push(e.data);
    // }

    // start recording user media
    this.mediaRecorder.start();

    return new Promise((resolve, reject) => {
      try {
        // process blob
        this.mediaRecorder.ondataavailable = (e) => {
          this._chunks.push(e.data);

          const processed = this._processChunks()

          // return processed blob when ready
          resolve(processed)
        }
      } catch (error) {
        reject(error)
      }
    })
  }
  
  stop = () => {
    if (this.mediaRecorder.state === 'recording') this.mediaRecorder.stop()
  }
}