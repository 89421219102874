export default {
  "children": [
    {
      name: 'PitchShift',
      settings: {
        'pitch': -2,  // influenced by form data
        'depth': 0.5,
        'wet': 0.7
      },
      modulator: 'generation',
      modulatorParam: 'pitch',
      modulatorValues: {
        'months': 2.5,
        'generations': 0.5,
        'dreams': -3
      }
    },
    {
      name: 'Chorus',
      settings: {
        'frequency': 4.5,
        'delayTime': 3.5,
        'depth': 0.7,
        'type': 'sine',
        'spread': 180,
        'wet' : 0.7
      },
      modulator: 'none',
      modulatorParam: 'none',
      modulatorValues: {}
    },
    {
      name: 'Reverb',
      settings: {
        'decay': 5,
        'wet' : 0.7  // influenced by form data
      },
      modulator: 'distance',
      modulatorParam: 'wet',
      modulatorValues: {
        'min': 0.3,
        'max': 1.0
      }
    },
    {
      name: 'AutoPanner',
      settings: {
        'frequency': 0.075,
        'depth': 0.5,
        'wet': 0.7
      },
      modulator: 'none',
      modulatorParam: 'none',
      modulatorValues: {}
    }
  ],
  "village" : [
    {
      name: 'Filter',
      settings: {
        'frequency': 400,  // influenced by form data
        'type': 'lowpass',
        'wet': 0.7
      },
      modulator: 'generation',
      modulatorParam: 'frequency',
      modulatorValues: {
        'months': 250,
        'generations': 600,
        'dreams': 1000
      }
    },
    {
      name: 'Chorus',
      settings : {
        'frequency': 0.85, // influenced by form data
        'delayTime': 2.5,
        'depth': 0.8,
        'type': 'sawtooth',
        'spread': 180,
        'wet': 0.8
      },
      modulator: 'distance',
      modulatorParam: 'frequency',
      modulatorValues: {
        'min': 0.4,
        'max': 1.0
      }
    },
    {
      name: 'Reverb',
      settings: {
        'decay': 5,
        'wet': 1.0
      },
      modulator: 'none',
      modulatorParam: 'none',
      modulatorValues: {}
    },
    {
      name: 'PingPongDelay',
      settings: {
        'delayTime': 2, // influenced by form data
        'feedback': 0.8,
        'wet': 0.7  
      },
      modulator: 'distance',
      modulatorParam: 'delayTime',
      modulatorValues: {
        'min': 1.0,
        'max': 25.0
      }
    }
  ],
  "revolution": [
    {
      name: 'BitCrusher',
      settings: {
        'bits': 4,
        'wet': 1.0  // influenced by form data
      },
      modulator: 'generation',
      modulatorParam: 'wet',
      modulatorValues: {
        'months': 0.3,
        'generations': 0.75,
        'dreams': 1.0
      }
    },
    {
      name: 'Tremolo',
      settings: {
        'frequency': 5,
        'depth': 0.8,
        'wet': 0.7
      },
      modulator: 'none',
      modulatorParam: 'none',
      modulatorValues: {}
    },
    {
      name: 'Distortion',
      settings: {
        'distortion': 0.075,  // influenced by form data
        'wet': 0.7
      },
      modulator: 'distance',
      modulatorParam: 'distortion',
      modulatorValues: {
        'min': 0.3,
        'max': 1.0
      }
    },
    {
      name: 'Chorus',
      settings: {
        'frequency': 2,
        'delayTime': 3.5,
        'depth': 0.7,
        'type': 'sine',
        'spread': 180,
        'wet': 0.6
      },
      modulator: 'none',
      modulatorParam: 'none',
      modulatorValues: {}
    },
    {
      name: 'FeedbackDelay',
      settings: {
        'delayTime': 1,
        'feedback': 0.2,
        'wet': 0.8
      },
      modulator: 'none',
      modulatorParam: 'none',
      modulatorValues: {}
    },
    {
      name: 'Reverb',
      settings: {
        'decay': 5,
        'wet': 0.7
      },
      modulator: 'generations',
      modulatorParam: 'wet',
      modulatorValues: {
        'months': 0.3,
        'generations': 0.65,
        'dreams': 0.9
      }
    }
  ]
}