import React from 'react';

import style from './Header.module.scss';

const Header = () => {
  return (
    <header className={style.header}>
      <h1 className={style.title}>Welcome to Malayeen Space</h1>
      <h3 className={style.subtitle}>A Gift Exchange of Song To and From the Origin</h3>
    </header>
  )
}

export default Header;