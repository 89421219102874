import React, { useRef, useCallback } from "react";
import { useJsApiLoader, Autocomplete } from "@react-google-maps/api";

const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY_NEW;
const api_libraries = ["places"];

export default function LocationInput({
  name,
  placeholder,
  value,
  onFocus = () => {},
  onChange,
}) {
  const inputRef = useRef();
  const autocompleteRef = useRef();

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: API_KEY,
    libraries: api_libraries,
  });

  const onLoad = useCallback((autocomplete) => {
    autocompleteRef.current = autocomplete;
  }, []);

  const onPlaceChanged = useCallback(() => {
    try {
      const place = autocompleteRef.current.getPlace();

      onChange({
        name: name,
        value: place.formatted_address,
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      });
    } catch (error) {
      console.error(`>> couldn't get ${name}, ${error}`);
    }
  }, [name, onChange]);

  return (
    isLoaded && (
      <>
        <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
          <input
            ref={inputRef}
            type="text"
            name={name}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            onFocus={onFocus}
          />
        </Autocomplete>
      </>
    )
  );
}
/*
resources
https://www.npmjs.com/package/@react-google-maps/api
https://react-google-maps-api-docs.netlify.app/#distancematrixservice
https://stackoverflow.com/questions/62888723/how-do-i-use-distance-matrix-api-in-reactjs-using-react-google-maps-api

https://react-google-maps-api-docs.netlify.app/#autocomplete
https://developers.google.com/maps/documentation/embed/get-api-key?authuser=1
*/
