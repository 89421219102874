import {
  GET_LIST,
  ADD_TO_LIST,
  REMOVE_FROM_LIST,
  UPDATE_LIST,
  MEDIA_STATE,
  SET_LOCATION,
  SET_ORIGIN,
  SET_DISTANCE,
  INVITE_MODAL_STATE
} from './actionTypes';

export default function reducer(state, action) {
  switch (action.type) {
    case GET_LIST:
      return {
        ...state,
        record: action.payload
      }
    case ADD_TO_LIST:
      // console.log('add to list', action.payload)
      const newItem = action.payload;
      return {
        ...state,
        record: [...state.record, newItem]
      }
    case UPDATE_LIST:
      const editedList = action.payload;
      const updatedList = state.record.map(item => item._id === editedList._id ? editedList : item)
      return {
        ...state,
        record: updatedList
      }
    case REMOVE_FROM_LIST:
      const filteredList = state.record.filter(item => item._id !== action.payload)
      return {
        ...state,
        record: filteredList
      }
    case MEDIA_STATE: {
      return {
        ...state,
        mediaState: action.payload
      }
    }
    case SET_LOCATION: {
      return {
        ...state,
        location: action.payload
      }
    }
    case SET_ORIGIN: {
      return {
        ...state,
        origin: action.payload
      }
    }
    case SET_DISTANCE: {
      return {
        ...state,
        distance: action.payload
      }
    }
    case INVITE_MODAL_STATE: {
      return {
        ...state,
        inviteModal: action.payload
      }
    }
    default:
      {
        return state;
      }
  }
}