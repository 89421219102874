import axios from 'axios';

import { config } from "../config/config"

import buildname from '../utilities/buildname'

export class recordService {
  static url = `${config.apiUrl}/record`;

  // use for private record view
  static async fetchList() {
    try {
      const response = await axios.get(this.url);
      return response.data.data;
    } catch (error) {
      return error;
    }
  }

  static async addToList(data) {
    // format blobname from form data
    const namedData = Object.assign({}, data, buildname(data)) 
    
    try {
      const response = await axios.post(this.url, namedData);

      return response.data.data;
    } catch (error) {
      console.log('error adding', error)
      return error;
    }
  }

  static async updateList(data) {
    try {
      await axios.put(this.url, data);
      return data;
    } catch (error) {
      return error;
    }
  }

  static async deleteFromList(id) {
    try {
      await axios.delete(this.url, { data: { id } });
      return id;
    } catch (error) {
      return error;
    }
  }

  static async emailRecord(form) {
    console.log('>> emailing:', form.name, form.email)
    try {
      await axios({
        method: "POST",
        url: `${this.url.replace('api/v1/record','')}email`,
        data: {
          to: `${form.name} <${form.email}>`,
          subject: "Here are your recordings from Malayeen.space",
          body: form.filename || 'no body data',
        },
      });
    } catch (error) {
      return error
    }
  }
}