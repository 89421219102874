import React from 'react'

import style from './About.module.scss'

export default function About() {
  return (
    <div className={style.modal}>
      <p>
        MALAYEEN.SPACE was conceived by Leyya Mona Tawil and developed in
        collaboration with Andrej Hrončo. It exists as part of Lime Rickey
        International’s Noise & Nation - an accumulation of transmissions that
        attend to migration, temporality and a post-border dream.
      </p>
      <p>
        An interactive audio experience in itself, Malayeen.Space also generates
        a community sound archive of the diasporic imagination. The song that
        plays back is modulated by the participant’s unique answers. The audio
        transformations are governed by the distance (in km) between you and the
        location of your song’s origin, your distance in time to this place, and
        the song’s intent.
      </p>
      <p>
        Malayeen.Space was created during Leyya’s Net//Work Residency, and was
        exhibited in May 2021 by the British Council in partnership with Wysing
        Arts Centre and Digital Arts Studios. In September 2021, the Malayeen
        Voices project will be presented by the Arab American National Museum as
        part of the Jam3a Music & Arts Festival and Leyya’s artist residency. It
        is also supported by the East Bay Community Foundation (Oakland) and the
        California Arts Council.
      </p>
      <p>
        Concept/Design -{" "}
        <a
          href="https://www.limerickeyinternational.com/"
          rel="noopener noreferrer"
          target="_blank"
        >
          Leyya Mona Tawil [Lime Rickey International]
        </a>
      </p>
      <p>
        Code/Design -{" "}
        <a
          href="https://andrejhronco.com"
          rel="noopener noreferrer"
          target="_blank"
        >
          Andrej Hrončo
        </a>
      </p>
    </div>
  );
}