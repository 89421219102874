import React, { useContext, useState, useEffect, useRef } from 'react';
import * as Tone from 'tone'

import Store from '../../store/store';
import { MEDIA_STATE } from '../../store/actionTypes';
import { recordService } from '../../services/recordService';
import AudioRecorder from '../../recorder/AudioRecorder'
import songPlayer from '../../player/player'

import style from './Recorder.module.scss';

const debugType = localStorage.getItem('debug')
const debug = debugType === 'recorder'

const RECORD_TIMEOUT = 1000 * 60 * 3
const RECORD_TIMEOUT_WARNING = 1000 * 8

const Recorder = ({onSubmit, clearForm, step}) => {
  const { dispatch } = useContext(Store);
  const [ recording, setRecording ] = useState(false)
  const [ endNotice, setEndNotice ] = useState(false)
  const recorder = useRef(null)
  const form = useRef({})
  const stream = useRef({})
  const initRecorder = useRef(false)
  const recordTimer = useRef(null)
  
  useEffect(() => {
    const initRecorder = async () => {
      stream.current = await navigator.mediaDevices.getUserMedia({ audio: true });
      console.log('>> init recorder stream: active', stream.current.active)
    }
    
    initRecorder()
  }, []);

  const startRecorder = async (formData, distance=1) => {
    // create new instance of the mediarecorder
    recorder.current = new AudioRecorder(stream.current)
    // init mediarecorder
    if(!initRecorder.current) {
      await recorder.current.init()
      initRecorder.current = true
    }
    // set form data
    form.current = formData
    // initiate and start recording
    dispatch({
      type: MEDIA_STATE,
      payload: 'recording'
    })
    // start media recorder
    if(debug) console.log('>> start recording')
    const audioBlob = await recorder.current.start(formData, false)
    if(debug) console.log('>> audioBlob', audioBlob)

    const stopped = songPlayer(audioBlob.url, formData, distance)
    
    dispatch({
      type: MEDIA_STATE,
      payload: 'playback'
    })

    stopped.then(() => {
      dispatch({
        type: MEDIA_STATE,
        payload: 'stopped'
      })

      // reset Form
      clearForm()
    })
  }

  const stopRecorder = async () => {
    if(debug) console.log('>> stop recording')
    recorder.current.stop()

    // email user recordings
    if(form.current.name && form.current.email) {
      await recordService.emailRecord(form.current)
    }

    dispatch({
      type: MEDIA_STATE,
      payload: 'playback'
    })
  }

  const onRecord = async () => {
    if (Tone.context.state !== 'running') {
      Tone.context.resume();
    }

    // submit form data
    const {
      formData,
      distance
    } = await onSubmit()
    
    if(debug) console.log('formData:onRecord', formData, 'distance', distance)
    
    // enable stop button
    setRecording(true)
    // pass to AudioRecorder
    startRecorder(formData, distance)

    // auto stop after 3min
    recordTimer.current = setTimeout(() => {
      // after the outer timeout resolves set this to the inner timeout
      recordTimer.current = setTimeout(() => {
        setRecording(false)
        stopRecorder()
        // clear end notice style
        setEndNotice(false)
      }, RECORD_TIMEOUT_WARNING) // n seconds notice before stopping
      // apply styles
      setEndNotice(true)
    }, RECORD_TIMEOUT - RECORD_TIMEOUT_WARNING)
  }

  const onStop = async () => {
    // stop recording and process data
    if(recording) {
      // disable stop button
      setRecording(false)
    }

    stopRecorder()

    // cancel autostop
    clearInterval(recordTimer.current)
    // clear end notice style
    setEndNotice(false)
  }

  return (
    <>
      <button
        className={`${style.recordbutton} ${recording ? style.recording : ''} ${step === 7 ? style.current : ''}`}
        onClick={onRecord}
        disabled={recording}
      >{recording ? 'Recording' : 'Record'}</button>
      <button
        className={`${style.stopbutton} ${step === 7 ? style.current : ''} ${endNotice ? style.endnotice : ''}`}
        onClick={onStop}
        disabled={!recording}
      >Stop</button>
    </>
  )
}

export default Recorder