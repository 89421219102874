import React, { useEffect, useRef, useState } from 'react'
import Bowser from "bowser";

import style from './Invite.module.scss'

export default function Invite() {
    const currentBrowser = useRef();
    const browser = Bowser.getParser(window.navigator.userAgent);
    currentBrowser.current = browser.getBrowser();
    const [showNotice, setNoticeDisplay] = useState(false);

    useEffect(() => {
      if(currentBrowser.current.name === 'Chrome' || currentBrowser.current.name === 'Firefox') {
      // allowed browsers
      } else {
        setNoticeDisplay(true);
      }
    }, [currentBrowser])

  return (
    <div className={style.modal}>
      <p>YOU ARE INVITED TO SING TO THE STARS!</p>
      <p>
        YOUR SONG WILL BE PLAYED BACK TO YOU IMMEDIATELY, HAVING BEEN
        TRANSFORMED BY YOUR UNIQUE ANSWERS ABOUT TIME, ORIGIN, AND SUBJECT. 
      </p>
      <p>FEEL FREE TO PLAY ANONYMOUSLY.</p>
      <p>
        IF YOU WOULD LIKE YOUR SONG RECORDED AND SENT TO YOU, SUBMIT YOUR EMAIL
        BEFORE PRESSING "SING"
      </p>
      {showNotice && (
        <p>
          <i>**Please use Chrome or Firefox to operate Malayeen.Space</i>
        </p>
      )}
    </div>
  );
}