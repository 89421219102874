import React from 'react'
import { Canvas } from '@react-three/fiber'
import { OrbitControls, useContextBridge } from '@react-three/drei'

import Store from '../../store/store';

import StarField from './StarField.jsx'

export default function Scene() {
  const ContextBridge = useContextBridge(Store)

  return (
    <Canvas>
      <ContextBridge>
        <ambientLight intensity={0.5} />
        <StarField />
        <OrbitControls />
      </ContextBridge>
    </Canvas>
  )
}