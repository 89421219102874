import React, { useContext, useReducer } from 'react';

import Store from './store/store';
import reducer from './store/reducer';

import BrowserNoticeModal from './components/BrowserNotice/BrowserNoticeModal';
import Scene from './components/Visuals/Scene';
import Header from './components/Header/Header';
import AboutModal from './components/About/AboutModal';
import InviteModal from "./components/Invite/InviteModal";
import Form from './components/Form/Form';
import Footer from './components/Footer/Footer';

import style from './App.module.scss';

const App = () => {
  const initialState = useContext(Store);
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <Store.Provider value={{ state, dispatch }}>
      <div className={style.app}>
        <Scene />
        <Header />
        <BrowserNoticeModal />
        <InviteModal />
        <AboutModal />
        <Form />
        <Footer/>
      </div>
    </Store.Provider>
  );
}

export default App;
