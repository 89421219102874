import sanitize from 'sanitize-filename'

export default function buildname(data) {
  const ext = 'wav'

  try {
    let {
      name,
      location,
      origin,
      songname
    } = data
    
    const formattedName = removeChars(`${name}-${location}-${origin}-${songname}`)
    const timestamp = Date.now()

    return { 
      filename: sanitize(`${formattedName}-${timestamp}.${ext}`)
    }
  } catch (error) {
    console.log('>> could not build filename', error.toString())
  }
}

function removeChars(str) {
  return str.trim().replace(/[.,\s+]/g, '-').replaceAll('--','-').toLowerCase()
}